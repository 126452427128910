


































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateBairro, dispatchGetBairros } from '@/store/bairro/actions';
import { IBairroCreate } from '@/interfaces/bairro';

@Component
export default class CadastrarBairro extends Vue {
  public valid = false;
  public nome: string = '';

  public async mounted() {
    await dispatchGetBairros(this.$store);
    this.reset();
  }

  public reset() {
    this.nome = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const novoBairro: IBairroCreate = {
        nome: this.nome,
      };
      const cadastrou = await dispatchCreateBairro(this.$store, novoBairro);
      if (cadastrou) {
        this.$router.push('/main/bairros/listar');
      }
    }
  }
}
